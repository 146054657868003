import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Videos from 'components/category/videos'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/construction/skid-steers/skid-steers/',
    imageId: 'skidSteersImage',
    name: 'Skid steers',
    alt: 'John Deere Skid Steers',
  },
  {
    link: '/construction/track-loaders/compact-track-loaders/',
    imageId: 'trackLoadersImage',
    name: 'Track Loaders',
    alt: 'John Deere Track Loaders',
  },
  {
    link: '/construction/excavators/compact-excavators/',
    imageId: 'excavatorsImage',
    name: 'Excavators',
    alt: 'John Deere Excavators',
  },
  {
    link: '/construction/wheel-loaders/compact-wheel-loaders/',
    imageId: 'wheelLoadersImage',
    name: 'Wheel Loaders',
    alt: 'John Deere Wheel Loaders',
  },
  {
    link: '/construction/attachments/',
    imageId: 'attachmentsImage',
    name: 'Construction Attachments',
    alt: 'John Deere Construction Attachments',
  },
]

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'Construction',
      'item': 'https://www.hutsoninc.com/construction/',
    },
  ],
})

const ConstructionPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  const description =
    'Your operation is your pride and joy. We get that. and we know that the demands you must meet to stay on top of your game never let up. That’s why we offer a complete line of compact equipment to help you keep up. From job-proven K-Series loaders and G-Series Compact Excavators, to our newest G-Series Skid Steers and Compact Track Loaders, to over 100 Worksite Pro attachment options that connect interchangeably across our compact product range, we’ve got you covered. So no you can imagine even more for your operation.'
  return (
    <Layout>
      <Helmet>
        <title>John Deere Construction Equipment | Hutson Inc</title>
        <meta name='description' content={description.slice(0, 157) + '...'} />
        <meta
          name='keywords'
          content='excavators, skid steers, track loaders, wheel loaders, John Deere, construction, worksite'
        />
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Construction Equipment' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Construction Equipment'>
          <p>{description}</p>
        </Copy>

        <Promos data={promos} type='Construction Equipment' />

        <Videos
          type='Construction Equipment'
          id='HO4giZNKp1w'
          list='PLUz5fj7f_mw-d2X9zmFD7g2NOPP_3MK7Y'
        />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query constructionCategoryQuery($category: String = "construction") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "construction/construction-bg.jpg" }) {
      ...FullWidthImage
    }
    skidSteersImage: file(relativePath: { eq: "construction/skid-steers.jpg" }) {
      ...FloatingGridImage
    }
    trackLoadersImage: file(relativePath: { eq: "construction/track-loaders.jpg" }) {
      ...FloatingGridImage
    }
    excavatorsImage: file(relativePath: { eq: "construction/excavators.jpg" }) {
      ...FloatingGridImage
    }
    wheelLoadersImage: file(relativePath: { eq: "construction/wheel-loaders.jpg" }) {
      ...FloatingGridImage
    }
    attachmentsImage: file(relativePath: { eq: "construction/attachments.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default ConstructionPage
